<template>
	<div class="home">
		<Component
			:is="sectionsByName[section.sectionName]"
			v-for="(section, index) in sections"
			:key="`section:${section.sectionName}:${index}`"
			v-bind="section.config"
		/>
	</div>
</template>

<script setup lang="ts">
import type { Section } from '@SHARED/core/entities/WebsiteConfig';

import { sectionsByName } from '@/components/sections';

const sections = useState<Section[]>('sections');

definePageMeta({
	name: 'Home',
	pageTitle: 'Home'
});
</script>

<style lang="postcss" scoped>
.home {
	background-color: #fff;
	width: 100%;
}
</style>
