<template>
	<PaddingContainer
		class="media-wrapper"
		:class="{
			'media-section-padding': !padding
		}"
		component="section"
		:padding="padding"
	>
		<div v-if="title || description" class="media-text-wrapper">
			<ResponsiveText
				v-if="title"
				v-bind="title"
				tag="span"
				:font-family="title.fontFamily || 'heading'"
			/>

			<ResponsiveText
				v-if="description"
				v-bind="description"
				tag="span"
				:font-family="description.fontFamily || 'default'"
			/>
		</div>

		<video v-if="media.isVideo" loop muted autoplay playsinline>
			<source :src="media.url" type="video/mp4" />
		</video>
		<NuxtImg
			v-else
			:alt="media.alt"
			:src="media.url"
			sizes="500px md:800px lg:1000px"
			placeholder
			loading="lazy"
		/>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type { MediaSection } from '@SHARED/core/entities/sections/MediaSection';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { CSSObjectFit, CssSize } from '@SHARED/utils/helperTypes';
import { getCSSColorVar } from '@SHARED/utils/style';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ResponsiveText from '@/components/atoms/ResponsiveText.vue';

defineOptions({ name: 'MediaSection' });

const styles = useState<WebsiteStyles>('styles');

const props = withDefaults(defineProps<MediaSection['config']>(), {
	title: null,
	description: null,
	backgroundColor: 'white',
	padding: null
});

const backgroundColor = computed<string>(() =>
	getCSSColorVar(props?.backgroundColor || styles.value.appearance.background)
);

const mediaObjectFit = computed<CSSObjectFit>(
	() => props.media?.objectFit || 'none'
);

const mediaMobileHeight = computed<CssSize | 'auto'>(
	() => props.media?.height?.mobile || 'auto'
);

const mediaDesktopWidth = computed<CssSize | 'auto'>(
	() => props.media?.width?.desktop || 'auto'
);

const mediaDesktopHeight = computed<CssSize | 'auto'>(
	() => props.media?.height?.desktop || 'auto'
);

const mediaMobileWidth = computed<CssSize | 'auto'>(
	() => props.media?.width?.mobile || 'auto'
);

const mobileGapBetweenTextAndMedia = computed<CssSize>(
	() => props.gapBetweenTextAndMedia?.mobile || '0'
);

const desktopGapBetweenTextAndMedia = computed<CssSize>(
	() => props.gapBetweenTextAndMedia?.desktop || '0'
);

const mobileGapBetweenTitleAndDescription = computed<CssSize>(
	() => props.gapBetweenTitleAndDescription?.mobile || '0'
);

const desktopGapBetweenTitleAndDescription = computed<CssSize>(
	() => props.gapBetweenTitleAndDescription?.desktop || '0'
);
</script>

<style lang="scss" scoped>
// <!-- * Os seletores abaixo são apenas para remover os controles de vídeo no iOS
*::-webkit-media-controls {
	&-panel,
	&-play-button,
	&-start-playback-button {
		display: none !important;
		appearance: none;
		-webkit-appearance: none;
	}
}

.media-text-wrapper {
	display: flex;
	flex-direction: column;

	gap: v-bind(mobileGapBetweenTitleAndDescription);

	@include screen-up(lg) {
		gap: v-bind(desktopGapBetweenTitleAndDescription);
	}
}

.media-section-padding {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.media-wrapper {
	background-color: v-bind(backgroundColor);
	width: 100%;
	display: flex;
	flex-direction: column;

	gap: v-bind(mobileGapBetweenTextAndMedia);

	@include screen-up(lg) {
		gap: v-bind(desktopGapBetweenTextAndMedia);
	}

	img,
	video {
		object-fit: v-bind(mediaObjectFit);
		height: v-bind(mediaMobileHeight);
		width: v-bind(mediaMobileWidth);

		@include screen-up(lg) {
			height: v-bind(mediaDesktopHeight);
			width: v-bind(mediaDesktopWidth);
		}
	}
}
</style>
