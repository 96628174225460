<template>
	<Component :is="tag" class="responsive-text">
		{{ content }}
	</Component>
</template>

<script setup lang="ts">
import type { KollerSectionText } from '@SHARED/core/entities/sections/KollerSection';
import type { CssSize } from '@SHARED/utils/helperTypes';

defineOptions({ name: 'ResponsiveText' });

const allowedTags = ['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

type TextTag = (typeof allowedTags)[number];

type ResponsiveParagraph = KollerSectionText & {
	tag?: TextTag;
};

const props = withDefaults(defineProps<ResponsiveParagraph>(), {
	fontFamily: 'default',
	fontWeight: 400,
	lineHeight: '150%',
	tag: 'span'
});

const tag = computed<TextTag>(() =>
	allowedTags.includes(props.tag) ? props.tag : 'span'
);

const fontFamily = computed(() => `var(--${props.fontFamily}-font)`);

const mobileFontSize = computed<CssSize>(
	() => props.fontSize.mobile || props.fontSize.desktop || '1rem'
);

const desktopFontSize = computed<CssSize>(
	() => props.fontSize.desktop || props.fontSize.mobile || '1rem'
);

const mobileDisplay = computed(() =>
	props.screenSizesToHide?.includes('mobile') ? 'none' : 'block'
);

const desktopDisplay = computed(() =>
	props.screenSizesToHide?.includes('desktop') ? 'none' : 'block'
);
</script>

<style lang="scss" scoped>
.responsive-text {
	line-height: v-bind(lineHeight);
	font-family: v-bind(fontFamily);
	font-weight: v-bind(fontWeight);
	font-size: v-bind(mobileFontSize);
	display: v-bind(mobileDisplay);

	@include screen-up(lg) {
		display: v-bind(desktopDisplay);
		font-size: v-bind(desktopFontSize);
	}
}
</style>
