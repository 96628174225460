<template>
	<section class="section-block" v-bind="$attrs">
		<slot></slot>
	</section>
</template>
<script setup lang="ts">
import type { BaseSectionConfig } from '@SHARED/core/entities/sections/BaseSection';

const props = defineProps<BaseSectionConfig>();

type SectionDisplays = 'none' | 'flex';

const getSectionDisplay = (showOnDevice?: boolean): SectionDisplays =>
	showOnDevice ? 'flex' : 'none';

const showOnDesktop = computed<SectionDisplays>(() =>
	getSectionDisplay(props.showOnDevice?.desktop ?? true)
);

const showOnMobile = computed<SectionDisplays>(() =>
	getSectionDisplay(props.showOnDevice?.mobile ?? true)
);
</script>
<style lang="scss" scoped>
.section-block {
	display: v-bind(showOnMobile);
	@include screen-up(lg) {
		display: v-bind(showOnDesktop);
	}
}
</style>
