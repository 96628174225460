import HeaderSection from '@SHARED/components/sections/HeaderSection.vue';
import AboutSection from '@SHARED/components/sections/AboutSection.vue';
import ContactSection from '@SHARED/components/sections/ContactSection.vue';
import PropertyCarouselSection from '@SHARED/components/sections/PropertyCarouselSection.vue';
import PropertyListingSection from '@/components/sections/PropertyListingSection.vue';
import CondoListingSection from '@/components/sections/CondoListingSection.vue';
import BlogPostsListingSection from '@/components/sections/BlogPostsListingSection.vue';
import BlogPostsCarouselSection from '@/components/sections/BlogPostsCarouselSection.vue';
import HeroSection from '@/components/sections/HeroSection.vue';
import FeaturedItemsGridSection from '@/components/sections/FeaturedItemsGridSection.vue';
import KollerSection from '@/components/sections/KollerSection/index.vue';
import RecordsListingSection from '@/components/sections/RecordsListingSection.vue';
import ServicesListingSection from '@/components/sections/ServicesListingSection.vue';
import MediaSection from '@/components/sections/MediaSection.vue';

export type SectionComponent =
	| typeof HeaderSection
	| typeof PropertyListingSection
	| typeof PropertyCarouselSection
	| typeof CondoListingSection
	| typeof BlogPostsListingSection
	| typeof BlogPostsCarouselSection
	| typeof HeroSection
	| typeof ContactSection
	| typeof FeaturedItemsGridSection
	| typeof AboutSection
	| typeof KollerSection
	| typeof RecordsListingSection
	| typeof ServicesListingSection
	| typeof MediaSection;

export const sectionsByName: Record<
	SectionComponent['sectionName'],
	SectionComponent
> = {
	HeaderSection,
	PropertyListingSection,
	PropertyCarouselSection,
	CondoListingSection,
	BlogPostsListingSection,
	BlogPostsCarouselSection,
	AboutSection,
	HeroSection,
	ContactSection,
	FeaturedItemsGridSection,
	KollerSection,
	RecordsListingSection,
	ServicesListingSection,
	MediaSection
};
