<template>
	<NuxtImg
		:src="url"
		:alt="alt"
		class="image"
		:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
		loading="lazy"
	/>
</template>

<script setup lang="ts">
import type { KollerSection } from '@SHARED/core/entities/sections/KollerSection';

import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';

defineOptions({ name: 'KollerSectionImage' });

const props = defineProps<KollerSection['config']['image']>();

const mobileWidth = computed(() => props.width.mobile);
const desktopWidth = computed(() => props.width.desktop);

const mobileHeight = computed(() => props.height.mobile);
const desktopHeight = computed(() => props.height.desktop);
</script>

<style lang="scss" scoped>
.image {
	object-fit: cover;
	width: v-bind(mobileWidth);
	height: v-bind(mobileHeight);

	@include screen-up(lg) {
		width: v-bind(desktopWidth);
		height: v-bind(desktopHeight);
	}
}
</style>
