<template>
	<PaddingContainer
		class="koller-text-subsection"
		:padding="textSubsectionPadding"
		component="div"
		:use-fallback-container="false"
	>
		<div class="content-wrapper">
			<NuxtImg
				v-if="svgHeading"
				:src="svgHeading.url"
				:alt="svgHeading.text"
				:title="svgHeading.text"
				:aria-label="svgHeading.text"
				class="svg-heading"
			/>

			<div class="paragraphs">
				<KollerParagraph
					v-for="(paragraph, index) in paragraphs"
					:key="`koller-text-subsection:${index}`"
					v-bind="paragraph"
				/>
			</div>

			<div v-if="buttons" class="buttons">
				<ButtonBlock
					v-for="(button, index) in buttons"
					:key="`koller-buttons-subsection:${index}`"
					v-bind="button"
				/>
			</div>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type { KollerSection } from '@SHARED/core/entities/sections/KollerSection';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import KollerParagraph from '@/components/sections/KollerSection/KollerParagraph.vue';

defineOptions({ name: 'KollerTextSubsection' });

const props = defineProps<KollerSection['config']>();

const desktopGapBetweenParagraphsAndSvgHeading = computed(
	() => props.gapBetweenParagraphsAndSvgHeading?.desktop || 0
);
const mobileGapBetweenParagraphsAndSvgHeading = computed(
	() => props.gapBetweenParagraphsAndSvgHeading?.mobile || 0
);

const mobileSvgHeadingWidth = computed(
	() => props.svgHeading?.width.mobile || 'auto'
);
const desktopSvgHeadingWidth = computed(
	() => props.svgHeading?.width.desktop || 'auto'
);

const svgHeadingMobileDisplay = computed(() =>
	(props.screenSizesToHideSvg || []).includes('mobile') ? 'none' : 'block'
);

const svgHeadingDesktopDisplay = computed(() =>
	(props.screenSizesToHideSvg || []).includes('desktop') ? 'none' : 'block'
);

const desktopGapBetweenParagraphs = computed(
	() => props.gapBetweenParagraphs?.desktop || 0
);
const mobileGapBetweenParagraphs = computed(
	() => props.gapBetweenParagraphs?.mobile || 0
);

const mobileTextSubsectionMaxWidth = computed(
	() => props.textSubsectionMaxWidth?.mobile || 'auto'
);
const desktopTextSubsectionMaxWidth = computed(
	() => props.textSubsectionMaxWidth?.desktop || 'auto'
);

const mobileGapBetweenButtons = computed(
	() => props.gapBetweenButtons?.mobile || 0
);

const desktopGapBetweenButtons = computed(
	() => props.gapBetweenButtons?.desktop || 0
);
</script>

<style lang="scss" scoped>
.koller-text-subsection {
	.content-wrapper {
		display: flex;
		flex-direction: column;
		gap: v-bind(mobileGapBetweenParagraphsAndSvgHeading);
		max-width: v-bind(mobileTextSubsectionMaxWidth);

		@include screen-up(lg) {
			gap: v-bind(desktopGapBetweenParagraphsAndSvgHeading);
			max-width: v-bind(desktopTextSubsectionMaxWidth);
		}
	}

	.svg-heading {
		display: v-bind(svgHeadingMobileDisplay);
		width: v-bind(mobileSvgHeadingWidth);

		@include screen-up(lg) {
			display: v-bind(svgHeadingDesktopDisplay);
			width: v-bind(desktopSvgHeadingWidth);
		}
	}

	.paragraphs {
		display: flex;
		flex-direction: column;
		gap: v-bind(mobileGapBetweenParagraphs);
		text-align: left;
		font-family: var(--default-font);

		@include screen-up(lg) {
			gap: v-bind(desktopGapBetweenParagraphs);
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		gap: v-bind(mobileGapBetweenButtons);
		max-width: v-bind(mobileTextSubsectionMaxWidth);

		@include screen-up(lg) {
			gap: v-bind(desktopGapBetweenButtons);
			max-width: v-bind(desktopTextSubsectionMaxWidth);
		}
	}
}
</style>
