<template>
	<ResponsiveText v-bind="props" tag="p" />
</template>

<script setup lang="ts">
import type { KollerSectionText } from '@SHARED/core/entities/sections/KollerSection';

import ResponsiveText from '@/components/atoms/ResponsiveText.vue';

defineOptions({ name: 'KollerParagraph' });

const props = defineProps<KollerSectionText>();
</script>
