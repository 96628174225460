<template>
	<PaddingContainer
		class="records-listing-section"
		component="section"
		:padding="padding"
	>
		<div class="text-subsection">
			<KollerParagraph v-bind="title" />

			<KollerParagraph v-if="subtitle" v-bind="subtitle" />
		</div>

		<div class="records">
			<div
				v-for="(record, index) in records"
				:key="`record:${record.value}:${index}`"
				class="record"
			>
				<span class="record-value heading-font">{{ record.value }}</span>
				<span class="record-description default-font">{{
					record.description
				}}</span>
			</div>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { RecordsListingSection } from '@SHARED/core/entities/sections/RecordsListingSection';
import { getCSSColorVar } from '@SHARED/utils/style';
import { COLOR_SERVICE } from '@SHARED/utils/vueProvidersSymbols';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import KollerParagraph from '@/components/sections/KollerSection/KollerParagraph.vue';

const styles = useState<WebsiteStyles>('styles');

const colorService = inject(COLOR_SERVICE)!;

defineOptions({ name: 'RecordsListingSection' });

const props = withDefaults(defineProps<RecordsListingSection['config']>(), {
	subtitle: null,
	gapBetweenTitleAndSubtitle: null,
	gapBetweenTextAndRecords: null,
	gapBetweenRecords: null,
	padding: null
});

const desktopGapBetweenTitleAndSubtitle = computed(
	() => props.gapBetweenTitleAndSubtitle?.desktop || 0
);
const mobileGapBetweenTitleAndSubtitle = computed(
	() => props.gapBetweenTitleAndSubtitle?.mobile || 0
);

const desktopGapBetweenTextAndRecords = computed(
	() => props.gapBetweenTextAndRecords?.desktop || 0
);
const mobileGapBetweenTextAndRecords = computed(
	() => props.gapBetweenTextAndRecords?.mobile || 0
);

const desktopGapBetweenRecords = computed(
	() => props.gapBetweenRecords?.desktop || 0
);
const mobileGapBetweenRecords = computed(
	() => props.gapBetweenRecords?.mobile || 0
);

const backgroundColor = computed(() =>
	getCSSColorVar(props.backgroundColor || 'white')
);

const textColor = computed(() => getCSSColorVar(props.textColor || 'black'));

const separatorColor = computed(() => {
	if (!props.textColor) return getCSSColorVar('lightgray');

	return colorService.transparentizeColor(
		styles.value.colorPalette[props.textColor],
		0.5
	);
});
</script>
<style lang="scss" scoped>
.records-listing-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: v-bind(mobileGapBetweenTextAndRecords);

	background-color: v-bind(backgroundColor);
	color: v-bind(textColor);

	@include screen-up(lg) {
		gap: v-bind(desktopGapBetweenTextAndRecords);
	}

	.text-subsection {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: v-bind(mobileGapBetweenTitleAndSubtitle);
		color: inherit;
		text-align: center;

		@include screen-up(lg) {
			gap: v-bind(desktopGapBetweenTitleAndSubtitle);
		}
	}

	.records {
		display: grid;
		grid-template-columns: 1fr 1fr;
		color: inherit;

		$desktopRecordsGap: v-bind(desktopGapBetweenRecords);

		gap: v-bind(mobileGapBetweenRecords);

		@include screen-up(lg) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: calc($desktopRecordsGap / 2);

			& > :not([hidden]) ~ :not([hidden]) {
				border-color: v-bind(separatorColor);
				border-left-width: 1px;
				padding-left: calc($desktopRecordsGap / 2);
			}
		}

		.record {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			color: inherit;

			.record-value {
				font-size: 2rem;
				line-height: 130%;
				font-weight: 400;
				text-align: center;
				color: inherit;
			}

			.record-description {
				font-size: 0.75rem;
				line-height: 100%;
				font-weight: 300;
				text-align: center;
				color: inherit;
			}

			@include screen-up(lg) {
				gap: 0;

				.record-value {
					font-size: 3.75rem;
				}

				.record-description {
					font-size: 1rem;
				}
			}
		}
	}
}
</style>
